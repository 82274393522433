.vertical-timeline::before {
    /* width: 1px;
    border-style: dashed;
    border-color: #e5e5e5;
    border-width: 1px; */
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 18px;
    width: 1px;
    background: repeating-linear-gradient(to bottom, #a0a0a0, #a0a0a0 5px, transparent 5px, transparent 10px);

}

.vertical-timeline-element-content {
    margin-left: 45px;
    -webkit-box-shadow: 0 1px 2px #ddd;
    box-shadow: 0 1px 2px #ddd;
}